import React, { useEffect, useMemo, useState } from "react";
import "../redeem-voucher/RedeemVoucher.scss";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeaderMenu from "../../components/layout/HeaderMenu";
import * as PR from "../../prime-modules/index";
import { discountPayment, redeemVoucherAPI } from "../../services/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "../../context/ToastContext";
import RedeemVoucherSuccess from "./RedeemVoucherSuccess";
import RedeemVoucherBundleDetails from "./RedeemVoucherBundleDetails";
import RedeemVoucherTopUp from "./RedeemVoucherTopUp";
import { useNavigate } from "react-router-dom";
import { renderSpeed } from "../../utils/reuse";
import { cartFilterActions } from "../../store/cart";
import { globalConfig } from "../../constants";
import { v4 as uuidv4 } from 'uuid';
import { paymentActions } from "../../store/payment";
import { getPhoneObj } from "../../genericFunctions/GetAllCountries";
import { useTranslation } from "react-i18next";

const RedeemVoucher = () => {
  const { showToast, redeemVoucher, general, validations } = useToast();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const isAuth = useSelector(state => state.auth.isLoggedIn);
  const [bundleData, setBundleData] = useState("");
  const [disableBundleDetails, setDisableBundleDetails] = useState(false);
  const [voucherRedeemSuccess, setvoucherRedeemSuccess] = useState(false);
  const [topupExistingEsim, setTopupExistingEsim] = useState(false);
  const [userFormData, setUserFormData] = useState({});
  const [voucherTopUpData, setvoucherTopUpData] = useState();
  const userData = useSelector((state) => state.auth.sessionData);
  const cartData = useSelector((state) => state.cart.catalogCart);
  const randomId = useSelector((state) => state.cart.randomId);
  const headers = useMemo(() => {
    return { sessionid: userData.sessionId };
  }, [userData.sessionId]);
  const voucherCode = '';

  const formatInput = (value) => {
    let cleanedValue = value.replace(/-/g, '').replaceAll(' ', '');
    let formattedValue = cleanedValue.match(/.{1,4}/g)?.join('-') || '';
    return formattedValue.toUpperCase();
  };
  const handleChange = (e) => {
    const formatted = formatInput(e.target.value);
    formik.setFieldValue("promoCode", formatted)
  };

  const getVoucherDetailsData = (formValues) => {
    const getResponse = response => {
      if (response.result === "SUCCESS") {
        setLoading(false);
        const responseData = response?.data;
        const cartData = {
          name: responseData.name,
          description: responseData.description,
          quantity: 1,
          price: responseData.price,
          speed: responseData.speed,
          duration: responseData.duration,
          dataAmount: responseData.dataAmount,
          promoCode: formValues?.promoCode
        };
        dispatch(cartFilterActions.setRandomIdForPayment(uuidv4()))
        dispatch(cartFilterActions.setCatalogCart(cartData));
        responseData.durationdisplay = responseData?.description?.split(",")?.find(e => e?.includes("Days"))?.trim();
        responseData.displaySpeed = renderSpeed(responseData?.speed);
        responseData.roamingCountries = responseData?.countries[0]?.country?.name?.split("(")[0].trim();
        responseData.networks = responseData?.countries[0]?.networks;
        setBundleData(responseData)
        setDisableBundleDetails(true);
      } else {
        setLoading(false);
        const error = response.error;
        showToast(error.severity, error.errorMsg);
      }
    }
    redeemVoucherAPI(formValues, headers, dispatch, getResponse);
  }

  const initialValues = {
    promoCode: "",
    email: ""
  }

  const validationSchema = Yup.object().shape({
    promoCode: Yup.string().trim()
      .required(redeemVoucher.VOUC005),
    email: Yup.string()
      .trim()
      .email(validations.VLD0006)
      .required(validations.VLD0005)
  })

  const submitHandler = values => {
    const request = {...values}
    request.promoCode = request?.promoCode?.replaceAll("-", "")?.toLowerCase();
    setLoading(true);
    setDisableBundleDetails(false);
    setUserFormData(request);
    getVoucherDetailsData(request)
  }
  
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitHandler
  })

useEffect(() => {
  if(isAuth) {
    formik.setFieldValue("email", userData?.email)
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [isAuth])

  const createOrTopupEsim = (iccid='') => {
    setLoading(true);
    const getPaymentData = (response) => {
        if (response.result === "SUCCESS") {
            setLoading(false);
            setvoucherRedeemSuccess(true);
            const paymentResponseObj = response.data;
            if (paymentResponseObj && paymentResponseObj?.paymentResponse) {
              dispatch(paymentActions.setPaymentResponse(paymentResponseObj))
              navigate(`/${i18n.language}/redeem-voucher#success`);
           }
         } else {
            setLoading(false)
            const error = response.error;
            showToast(error.severity, error.errorMsg);
        }
    }
    const customerDetails = {
        firstName: userData.firstName ? userData.firstName : userFormData?.email?.split('@')[0],
        email: userData.email ? userData.email : userFormData.email,
        phone: getPhoneObj('', isAuth, userData, '')
    }
    const dataPlans = [cartData];
    const deviceInfo = { device: globalConfig.deviceInfo };
    const paymentData = {
        contactInfo: customerDetails,
        orderSummary: {
            dataPlans: dataPlans,
            totalOrderAmount: cartData?.price
        },
        iccid,
        paymentGateway: "prepaid",
        paymentMethodId: uuidv4(),
        randomId: randomId ? randomId : uuidv4(),
        isEsimCompatible: globalConfig.isEsimCompatible,
        deviceInfo
    }
    discountPayment(paymentData, headers, dispatch, getPaymentData);
}
  
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${general.GEN0001} | ${redeemVoucher?.VOUC001}`} </title>
      </Helmet>

      {/* Header Section */}
      <section className="inner-header-section voucher-header">
        <div className="header">
          <header>
            <HeaderMenu />
          </header>
          <div className="fixed-height"></div>
        </div>
        <div className="grid grid-nogutter header-text">
          <div className="col-12 text-center">
            <h1>{redeemVoucher?.VOUC001}</h1>
            <p>{redeemVoucher?.VOUC002}</p>
          </div>
        </div>
      </section>

      {!voucherRedeemSuccess &&
        <section className="voucher-section">
          <div className="grid header-text">
            <div className="col-12">

              {!topupExistingEsim &&
                <div className="vouchers-box">
                  <div className="form-wrapper text-center">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="input-field">
                        <PR.InputText id="promoCode" name="promoCode" className="w-12" placeholder={redeemVoucher.VOUC003} value={formik.values.promoCode} onChange={handleChange} onBlur={formik.handleBlur} maxLength={40} />
                        {formik.touched.promoCode && formik.errors.promoCode && <div className="error-message">{formik.errors.promoCode}</div>}
                      </div>
                      <div className="input-field marginTop">
                        <PR.InputText id="email" name="email" className="w-12" placeholder={redeemVoucher.VOUC004} value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} disabled={isAuth} />
                        {formik.touched.email && formik.errors.email && <div className="error-message">{formik.errors.email}</div>}
                      </div>
                      <PR.Button label={redeemVoucher.VOUC001} type='submit' className="details-btn" disabled={!formik.isValid} />
                    </form>
                  </div>
                </div>
              }

              {(disableBundleDetails && !topupExistingEsim) &&
                <RedeemVoucherBundleDetails bundleData={bundleData} userFormData={userFormData} voucherCode={voucherCode} setvoucherRedeemSuccess={setvoucherRedeemSuccess} setLoading={setLoading} setTopupExistingEsim={setTopupExistingEsim} setvoucherTopUpData={setvoucherTopUpData} createOrTopupEsim={createOrTopupEsim} />
              }

              {topupExistingEsim &&
                <RedeemVoucherTopUp voucherTopUpData={voucherTopUpData} createOrTopupEsim={createOrTopupEsim}/>
              }

              {loading &&
                <div className="loading-div">
                  <i className="pi pi-spin pi-spinner flex"></i>
                </div>
              }

            </div>
          </div>
        </section>
      }

      {voucherRedeemSuccess &&
        <RedeemVoucherSuccess />
      }

    </HelmetProvider>
  );
};

export default RedeemVoucher;